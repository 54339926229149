<template>
  <v-container id="add_user" tag="section" fluid>
    <v-card style="border-radius: 8px" elevation="3" active-class="b-radius">
      <service-full-form />
    </v-card>
  </v-container>
</template>

<script>
import ServiceFullForm from "./components/ServiceFullForm.vue";

export default {
  name: "AddService",
  components: { ServiceFullForm },
};
</script>
