<template>
  <plus-field
    ref="availabilityScheduleSelect"
    v-model="selectedScheduleId"
    :dense="dense"
    :disabled="disabled"
    :rules="rules"
    outlined
    small-chips
    deletable-chips
    :label="$trans('choose_availability_schedule')"
    action="availabilitySchedule/fetchAll"
    getter="availabilitySchedule/schedules"
    dialog-type="AVAILABILITY_SCHEDULE"
    :dialog-size="dialogSize.FULL_SCREEN"
    :dialog-title="$trans('add_new_availability_schedule')"
    dialog-cta-button-action="handleSave"
    dialog-cta-button-icon="$save"
    :dialog-cta-button-title="$trans('save')"
    :no-data-text="$trans('add_schedules_with_plus')"
    persistent
  >
    <template #default>
      <v-tooltip v-if="selectedScheduleId" bottom>
        <template #activator="{ on }">
          <div
            class="outlined-icon ml-2"
            @click="displayAvailabilitySchedule"
            v-on="on"
          >
            <v-icon>$pencil</v-icon>
          </div>
        </template>
        {{ $trans("display_schedule") }}
      </v-tooltip>
    </template>
  </plus-field>
</template>

<script>
import { mapGetters } from "vuex";
import PlusField from "@/components/Forms/PlusField";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import dialogTypes from "@/components/Dialogs/dialogTypes";

export default {
  components: { PlusField },
  mixins: [sharedActions],
  model: {
    prop: "scheduleId",
    event: "change",
  },
  props: {
    scheduleId: {
      type: Number,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedScheduleId: this.scheduleId,
    };
  },
  computed: {
    ...mapGetters({
      schedules: "availabilitySchedule/schedules",
    }),
    dialogSize() {
      return dialogSize;
    },
  },
  watch: {
    selectedScheduleId(val) {
      this.$emit("change", val);
    },
    scheduleId(val) {
      this.selectedScheduleId = val;
    },
  },
  methods: {
    displayAvailabilitySchedule() {
      let schedule = null;
      if (
        this.schedules &&
        this.schedules.length > 0 &&
        this.selectedScheduleId
      ) {
        schedule = this.schedules.find(
          (schedule) => schedule.id === this.selectedScheduleId
        );
      }

      this.openDialog({
        type: dialogTypes.AVAILABILITY_SCHEDULE,
        size: dialogSize.FULL_SCREEN,
        data: schedule,
        persistent: true,
        title: this.$trans("update_availability_schedule"),
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$save",
        ctaButtonTitle: this.$trans("save"),
      });
    },
  },
};
</script>
