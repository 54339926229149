import { mapActions } from "vuex";
import sharedFieldActions from "@/lib/calendesk-js-library/mixins/sharedFieldActions";
import { debounce } from "debounce";

export default {
  mixins: [sharedFieldActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Array, Object, Number, String],
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    multipleSelection: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    keepMenuOpenOnSelect: {
      type: Boolean,
      default: false,
    },
    returnIds: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      action: null, // abstract, override it
      extraDataAttrs: {},
      allItems: [],
      selectedItems: [],
      selectedItem: null,
      reducedSelectedItems: [],
      perPage: 50,
      page: 1,
      total: null,
      searchText: null,
      isLoading: false,
      skipInitialLoading: false,
    };
  },
  computed: {
    maxPages() {
      if (!this.searchText && this.total) {
        return Math.ceil(this.total / this.perPage);
      }

      return 1;
    },
    canLoadMorePages() {
      return !this.searchText && this.page < this.maxPages;
    },
    valueHasReallyChanged() {
      if (this.multipleSelection) {
        return (
          JSON.stringify(this.value) !== JSON.stringify(this.selectedItems)
        );
      } else {
        return JSON.stringify(this.value) !== JSON.stringify(this.selectedItem);
      }
    },
  },
  watch: {
    value() {
      if (this.valueHasReallyChanged) {
        this.assignValue();
        this.reloadItemsDebounced();
      }
    },
    searchText() {
      this.reloadItemsDebounced();
    },
    selectedItems(selectedItems) {
      if (selectedItems) {
        if (this.multipleSelection) {
          if (selectedItems.length > 0) {
            this.reducedSelectedItems = selectedItems.slice(
              0,
              this.maxItemsInSelectField + 1
            );
          } else {
            this.reducedSelectedItems = [];
          }
        } else {
          this.reducedSelectedItems = selectedItems;
        }
      } else {
        this.reducedSelectedItems = this.multipleSelection ? [] : null;
      }
    },
  },
  created() {
    if (!this.skipInitialLoading) {
      this.assignValue();
      this.reloadItems(true);
    }
  },
  methods: {
    ...mapActions({
      fetchLocations: "location/fetchLocations",
    }),
    assignValue(value = null) {
      if (this.multipleSelection) {
        this.selectedItems = value ? value : this.value || [];
      } else {
        this.selectedItem = value ? value : this.value || null;
      }
    },
    reloadItemsDebounced: debounce(function () {
      this.reloadItems();
    }, 300),
    reloadItems(initialLoading) {
      // Don't show loader for the initial loading
      if (!initialLoading) {
        this.isLoading = true;
      }

      let data = {
        limit: this.perPage,
        page: this.searchText ? 1 : this.page,
      };

      if (this.extraDataAttrs) {
        // Merge extraData into data
        data = { ...data, ...this.extraDataAttrs };
      }

      if (this.searchText) {
        // HACK: We need to return all the items in the first page when doing search. Or find a better way to handle it.
        // We could merge pages, but this could mix up with the "allItems".

        // Example of another filter, we might need it in the future.
        // data.filter = JSON.stringify({
        //   query: this.searchText,
        //   operator: "AND",
        //   service_ids: [99],
        // });

        data.query = this.searchText;
        data.limit = 9999;
      }

      return this[this.action](data)
        .then((response) => {
          this.parseResponse(response.data);

          this.page = response.current_page;
          this.total = response.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    parseResponse(newItems) {
      let mergedItems = [];
      const itemSet = new Set();

      // Helper function to add items if they are not duplicates
      const addItemIfNotDuplicate = (item) => {
        if (!itemSet.has(item.id)) {
          itemSet.add(item.id);
          mergedItems.push(item);
        }
      };

      if (!this.searchText) {
        // Filter out existing headers and dividers
        let existingItems = this.allItems.filter(
          (item) => !item.header && !item.divider
        );

        // Add existing items, checking for duplicates
        existingItems.forEach(addItemIfNotDuplicate);

        // Add new items from the server response
        newItems.forEach(addItemIfNotDuplicate);

        // Add selected items
        if (
          this.multipleSelection &&
          this.selectedItems &&
          this.selectedItems.length > 0
        ) {
          this.selectedItems.forEach(addItemIfNotDuplicate);
        } else if (!this.multipleSelection && this.selectedItem) {
          addItemIfNotDuplicate(this.selectedItem);
        }

        this.sortItems(mergedItems);
      } else {
        let selectedItems =
          this.multipleSelection && this.selectedItems
            ? [...this.selectedItems]
            : [];
        if (!this.multipleSelection && this.selectedItem) {
          selectedItems.push(this.selectedItem);
        }

        this.sortItems(selectedItems);

        // Merge new items with selected items, checking for duplicates
        newItems.forEach(addItemIfNotDuplicate);
        selectedItems.forEach(addItemIfNotDuplicate);
      }

      // Assign the filtered mergedItems to this.allItems
      this.allItems = mergedItems;
    },
    sortItems(items) {
      items.sort((a, b) => {
        return (a.id || 0) - (b.id || 0);
      });
    },
    onIntersect() {
      if (this.canLoadMorePages) {
        this.page += 1;
        this.reloadItemsDebounced();
      }
    },
    onInputChange() {
      if (
        !this.reducedSelectedItems ||
        this.reducedSelectedItems.length === 0
      ) {
        this.selectedItems = [];
        this.selectedItem = null;

        this.informAboutChanges();
      }
    },
    handleInputBlur() {
      // Because we load all the services (items) to the input, when leaving the input, we don't want to keep the search value.
      // For some reason, v-autocomplete doesn't reset this value, even though we use .sync option.
      setTimeout(() => {
        if (this.$refs.autocomplete && !this.$refs.autocomplete.isMenuActive) {
          this.searchText = null;
        }
      }, 500);
    },
    informAboutChanges() {
      if (!this.keepMenuOpenOnSelect) {
        this.$refs.autocomplete.isFocused = false;
      }

      if (this.multipleSelection) {
        let result = null;

        if (this.returnIds) {
          result = this.selectedItems.map((u) => u.id);
        } else {
          result = this.selectedItems
            ? this.$helpers.cloneObject(this.selectedItems)
            : null;
        }

        this.$emit("input", result);
        this.$emit("change", result);
      } else {
        let result = this.selectedItem || null;
        this.$emit("input", result);
        this.$emit("change", result);
      }
    },
    removeChip(item) {
      if (this.multipleSelection) {
        const index = this.findItemIndex(this.selectedItems, item);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      } else {
        this.selectedItem = null;
      }

      this.informAboutChanges();
    },
    findItemIndex(list, item) {
      return list.findIndex((listItem) => listItem.id === item.id);
    },
    areItemsEqual(item1, item2) {
      return item1 && item2 && item1.id === item2.id;
    },
    isItemSelected(item) {
      if (this.selectedItems) {
        if (this.multipleSelection) {
          const index = this.findItemIndex(this.selectedItems, item);
          return index > -1;
        } else if (this.selectedItem) {
          return this.areItemsEqual(this.selectedItem, item);
        }
      }

      return false;
    },
    toggleItem(item) {
      if (this.multipleSelection) {
        const index = this.findItemIndex(this.selectedItems, item);

        if (index > -1) {
          this.selectedItems.splice(index, 1);
        } else {
          this.selectedItems.push(item);
        }
      } else {
        this.selectedItem = this.areItemsEqual(this.selectedItem, item)
          ? null
          : item;
      }

      this.informAboutChanges();
    },
  },
};
