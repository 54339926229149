<template>
  <v-menu
    v-model="showMenu"
    transition="slide-y-transition"
    nudge-bottom="40"
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="selectedTime"
        v-mask="'##:##'"
        :class="{
          'c-time-input': !hideIcon,
          'c-time-input-small': hideIcon,
        }"
        dense
        outlined
        v-bind="attrs"
        :rules="[rules.time]"
        hide-details
        :disabled="disabled"
        @blur="onBlur(selectedTime)"
        @focus="$event.target.select()"
        @keypress.enter.prevent="onBlur(selectedTime)"
        v-on="on"
      >
        <template v-if="!hideIcon" #append>
          <v-icon v-on="on"> $clock</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-list class="c-text-field-list">
      <v-list-item-group>
        <v-list-item
          v-for="(el, i) in timeSlots"
          :key="i"
          @click="onSelect(el)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="el" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import {
  addMinutes,
  generateTimes,
} from "@/lib/calendesk-js-library/tools/helpers";
import { time } from "@/lib/calendesk-js-library/forms/validators";

export default {
  name: "CTimePicker",
  directives: { mask },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    time: {
      type: String,
      default: null,
    },
    startTime: {
      type: String,
      default: "00:00",
    },
    endTime: {
      type: String,
      default: "23:59",
    },
    spotsEvery: {
      type: Number,
      default: 5,
    },
    minimumTime: {
      type: String,
      default: "00:00",
    },
    increaseMinimumTime: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
      selectedTime: null,
      timeSlots: [],
      rules: {
        time,
      },
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
  },
  watch: {
    time(val) {
      this.selectedTime = val;
      this.onBlur(this.selectedTime);
    },
    minimumTime(val) {
      this.onBlur(this.selectedTime);
    },
  },
  created() {
    this.selectedTime = this.time;
    this.regenerateTimeSlots();
  },
  methods: {
    onBlur(val) {
      if (this.selectedTime && this.selectedTime.length) {
        this.selectedTime = this.processInput(val);

        const minimumTimeObj = this.$moment(
          this.minimumTime,
          this.$helpers.timeFormat
        );
        const selectedTimeObj = this.$moment(
          this.selectedTime,
          this.$helpers.timeFormat
        );

        if (minimumTimeObj.isSameOrAfter(selectedTimeObj)) {
          if (this.increaseMinimumTime) {
            this.selectedTime = addMinutes(this.minimumTime, 5);
          } else {
            this.selectedTime = this.minimumTime;
          }
        }

        this.$emit("update", this.selectedTime);

        this.regenerateTimeSlots();
      }
    },
    onSelect(item) {
      this.onBlur(item);
    },
    regenerateTimeSlots() {
      if (this.increaseMinimumTime) {
        this.timeSlots = generateTimes(
          addMinutes(this.minimumTime, 5),
          "23:59",
          this.spotsEvery
        );
      } else {
        this.timeSlots = generateTimes("00:00", "23:59", this.spotsEvery);
      }
    },
    processInput(input) {
      if (input) {
        switch (this.selectedTime.length) {
          case 1:
            return `0${input}:00`;
          case 2:
            if (input <= 23) {
              return `${input}:00`;
            }
            return this.validateInterval();

          case 4: {
            if (input[1] <= 5) {
              return `0${input[0]}:${input[1]}${input[3]}`;
            }
            return this.validateInterval();
          }
          case 5:
            return input;
          default:
            return this.validateInterval();
        }
      } else {
        return this.validateInterval();
      }
    },
    validateInterval() {
      return "00:00";
    },
  },
};
</script>
<style lang="scss" scoped>
.c-text-field-list {
  height: 200px;
  overflow-y: auto;
}

.c-time-input {
  width: 95px;
  max-width: 95px;
  min-width: 95px;
}

.c-time-input-small {
  width: 65px;
  max-width: 65px;
  min-width: 65px;
}
</style>
