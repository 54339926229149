<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="reducedSelectedItems"
    :search-input.sync="searchText"
    no-filter
    outlined
    :items="allItems"
    :label="
      !selectedItems || (multipleSelection && selectedItems.length === 0)
        ? label || $trans('service_location')
        : label || $trans('service_location')
    "
    :multiple="multipleSelection"
    hide-details="auto"
    item-text="id"
    :dense="dense"
    :rules="rules"
    :loading="isLoading"
    :disabled="disabled"
    :no-data-text="
      hideActions ? $trans('nothing_here') : $trans('add_locations_with_plus')
    "
    :placeholder="$trans('autocomplete_hint')"
    return-object
    :clearable="clearable"
    :attach="'#' + uniqueId"
    class="cd-fake-outer"
    @input="onInputChange"
    @blur="handleInputBlur"
  >
    <template #append-item>
      <v-list-item v-if="canLoadMorePages" v-intersect="onIntersect">
        <v-list-item-title>
          <div class="d-flex align-center justify-center pointer my-2">
            <span class="text-break cd-v-select-line-height text-wrap">
              {{ $trans("loading") }}
            </span>
          </div>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template #selection="data">
      <div class="d-flex align-center pointer" style="max-width: 99%">
        <template v-if="data.index < maxItemsInSelectField">
          <v-chip
            v-if="chips || smallChips"
            :disabled="disabled"
            :small="smallChips"
            :close="deletableChips"
            @click:close="removeChip(data.item)"
          >
            <v-icon
              v-if="getIconForType(data.item.type)"
              color="primary"
              size="20"
              class="mr-1 ml-n1"
            >
              {{ getIconForType(data.item.type) }}
            </v-icon>
            <img
              v-else
              height="15"
              :src="getImageForType(data.item.type)"
              :alt="$trans('service_location_' + data.item.type)"
              class="mr-2"
            />
            <span class="text-truncate">
              ({{ $trans("id") }}: {{ data.item.id }})
              <template v-if="data.item.name">{{ data.item.name }}</template>
              <template v-else>
                {{ $trans("service_location_" + data.item.type) }}
              </template>
              <template v-if="data.item.data"> ({{ data.item.data }})</template>
            </span>
          </v-chip>
          <template v-else>
            <span class="text-truncate">
              ({{ $trans("id") }}: {{ data.item.id }})
              <template v-if="data.item.name">{{ data.item.name }}</template>
              <template v-else>
                {{ $trans("service_location_" + data.item.type) }}
              </template>
              <template v-if="data.item.data"> ({{ data.item.data }})</template>
            </span>
          </template>
        </template>
        <div
          v-if="multipleSelection && data.index === maxItemsInSelectField"
          class="grey--text text-caption"
        >
          (+{{ selectedItems.length - maxItemsInSelectField }}
          {{ $trans("more") }})
        </div>
      </div>
    </template>

    <template #item="{ item, on, attrs }">
      <v-list-item
        v-bind="attrs"
        :class="{
          'primary--text v-list-item--active v-list-item--highlighted':
            isItemSelected(item),
        }"
        v-on="on"
        @click.stop="toggleItem(item)"
      >
        <v-list-item-icon class="d-flex justify-start">
          <v-icon v-if="getIconForType(item.type)" color="primary">
            {{ getIconForType(item.type) }}
          </v-icon>
          <img
            v-else
            height="20"
            :src="getImageForType(item.type)"
            :alt="$trans('service_location_' + item.type)"
          />
        </v-list-item-icon>
        <v-list-item-title>
          <span class="text-truncate">
            ({{ $trans("id") }}: {{ item.id }})
            <template v-if="item.name">{{ item.name }}</template>
            <template v-else>
              {{ $trans("service_location_" + item.type) }}
            </template>
            <template v-if="item.data"> ({{ item.data }})</template>
          </span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template #append-outer>
      <div :id="uniqueId"></div>

      <div v-if="!hideActions">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div
              class="outlined-icon outlined-icon-top-0 ml-2"
              @click="handleAddLocation(true)"
              v-on="on"
            >
              <v-icon>$plus</v-icon>
            </div>
          </template>
          {{ $trans("add") }}
        </v-tooltip>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import paginatedSelectInput from "@/lib/calendesk-js-library/mixins/paginatedSelectInput";
import locationActions from "@/calendesk/mixins/locationActions";
import { cloneObject } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "LocationSelectField",
  mixins: [paginatedSelectInput, locationActions],
  props: {
    hideActions: {
      type: Boolean,
      default: false,
    },
    locationIds: {
      type: [Array],
      default: null,
    },
  },
  data() {
    return {
      action: "fetchLocations",
      extraDataAttrs: { order_by: "id", ascending: 1 },
      skipInitialLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      shouldRefreshLocations: "location/shouldRefreshLocations",
      cachedLocations: "location/getCachedLocations",
    }),
  },
  watch: {
    shouldRefreshLocations(value) {
      if (value) {
        this.reloadItems();
      }
    },
  },
  created() {
    if (this.locationIds) {
      this.fetchLocationObjects(this.locationIds);
    }

    this.$watch("locationIds", this.fetchLocationObjects);
  },
  methods: {
    ...mapActions({
      fetchLocations: "location/fetchLocations",
      loadLocationsToCacheById: "location/loadLocationsToCacheById",
    }),
    fetchLocationObjects(locationIds) {
      this.loadLocationsToCacheById(locationIds).then(() => {
        this.assignLocationObjectsToValue(locationIds);
      });
    },
    assignLocationObjectsToValue(locationIds) {
      this.assignValue(
        this.cachedLocations
          .filter((location) => locationIds.includes(location.id))
          .map((location) => cloneObject(location))
      );
      this.reloadItems(true);
    },
  },
};
</script>
