<template>
  <v-container fluid>
    <v-row v-for="(item, index) in value" :key="index">
      <service-availability-picker-item
        :index="index"
        :item="item"
        @remove="remove"
        @change="onChange"
      />
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="primary" @click.native="add">
          {{ $trans("add_new_day") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ServiceAvailabilityPickerItem from "@/components/CSchedule/ServiceAvailabilityPickerItem";

export default {
  components: { ServiceAvailabilityPickerItem },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
  },
  methods: {
    remove(removeIndex) {
      const value = this.value.filter((item, index) => index !== removeIndex);
      this.$emit("change", value);
    },
    onChange(item) {
      const value = this.$helpers.cloneObject(this.value);
      value[item.index].day = item.day;
      value[item.index].date = item.date;
      value[item.index].start_time = item.start_time;
      this.$emit("change", value);
    },
    add() {
      const value = this.$helpers.cloneObject(this.value);
      value.push({
        start_time: this.configuration.calendar_time.from,
        day: "monday",
        date: null,
      });
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.day-select {
  width: 100%;
}
</style>
